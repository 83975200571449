<template>
  <h1>Cumulonimbus, the funny cloud platform.</h1>

  <div class="hero-points">
    <div>
      <h2>The only file hosting platform.*</h2>
      <p>
        Yes, this is totally the only file hosting platform, don't even bother
        with looking up other file hosting platforms, because they don't exist.
        You can trust me, I'm the marketing material that's supposed to convince
        you to use this platform.
        <br />
        <br />
        * This is not the only file hosting platform, pardon the sarcasm.
      </p>
    </div>
    <div>
      <h2>Large selection of domains.</h2>
      <p>
        With a large selection of domains already, we are always accepting more
        domains if you're open to supplying them!
      </p>
    </div>
    <div>
      <h2>Support for uploading services.</h2>
      <p>
        With official support for ShareX and other programs, we are always looking to
        expand what is officially supported, just let us know what you want to
        see added and we'll see what we can do!
      </p>
    </div>
    <div>
      <h2>Have questions?</h2>
      <p>
        Have a question or concern about Cumulonimbus, have a suggestion, or
        just want to chat with the developer? Join the
        <a href="https://alekeagle.com/d" rel="noopener" target="_blank"
          >Discord</a
        >
        server and talk with the rest of the community!
      </p>
    </div>
    <div>
      <h2>Lets get started!</h2>
      <p>
        Head to the <RouterLink to="/dashboard">dashboard</RouterLink> to get
        started!
      </p>
    </div>
    <div>
      <h2>Report Abuse</h2>
      <p>
        Find someone misusing and/or exploiting the Cumulonimbus platform? Send
        us an
        <a
          href="mailto:abuse@alekeagle.me?subject=Report%20Abuse&body=URL%2FLink%20to%20offending%20content%3A%20%5BReplace%20me%20with%20the%20link%20to%20the%20offending%20content%5D%0A%0AUse%20the%20space%20below%20to%20explain%20how%20the%20content%20above%20is%20potentially%20abusing%20the%20service%3A%0A%5BYour%20reasoning%20here%5D"
          rel="noopener"
          target="_blank"
          >abuse report</a
        >
        and we'll look into it right away.
      </p>
    </div>
  </div>
</template>
